<template>
  <q-table
    class="sticky-header-table superdense"
    ref="propertyGrid"
    :rows="propertygrid.records"
    :columns="propertygrid.columns"
    separator="vertical"
    row-key="caption"
    :rows-per-page-options="[0]"
  >
    <template v-slot:body-cell-caption="props">
      <q-td :props="props" :colspan="props.row.type === 'groupheader' ? 2 : 1">
        <div
          :class="
            props.row.type === 'groupheader'
              ? 'text-bold bg-' + props.row.color + '-5'
              : ''
          "
        >
          {{ props.row.caption }}
        </div>
      </q-td>
    </template>
    <template v-slot:body-cell-value="props">
      <q-td :props="props">
        <div v-if="props.row.type === 'boolean'">
          <q-icon
            size="xs"
            dense
            :name="props.row.value ? 'o_check_circle' : 'o_cancel'"
          />
        </div>
        <div
          v-else-if="
            props.row.type === 'datetime' && typeof props.row.value === 'string'
          "
        >
          {{ new Date(parseInt(props.row.value)).toLocaleString() }}
        </div>
        <div v-else>
          {{ props.row.value }}
        </div>
      </q-td>
    </template>
  </q-table>
</template>

<script lang="coffee">
  import { defineComponent, ref, onMounted, reactive, onUnmounted,watch } from 'vue'
  import { Propertygrid  } from "./propertygridCls.coffee"
  export default defineComponent({
    name: 'propertygrid',
    props:
      instancename:
        type: String
        default: 'propertygrid'
      objecttodisplay:
        default: {}
        type: Object
      groups:
        default: [
          {name:'General',color:'orange'},
          ]
        type: Array
      mapping:
        type: Object
        default: {}
    setup: (props) ->
      propertygrid = reactive(new Propertygrid({props:props}))
      propertygrid.vueBinder()
      onMounted(() => propertygrid.mounted())
      onUnmounted(() => propertygrid.unmounted())
      watch(() =>
        props.objecttodisplay
      ,(current,old) =>
        propertygrid.objecttodisplay=current
        propertygrid.createRecords()
      ,{immediate:true}
      )
      return {propertygrid}

  })
</script>
