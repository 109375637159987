<template>
  <q-table
    ref="metaDataBrowser"
    title="Metadata"
    class="sticky-header-table superdense"
    :rows="metadata.records"
    :columns="displaycolumns"
    row-key="metadataId"
    :rows-per-page-options="[0]"
    binary-state-sort
    :visible-columns="visibleColumns"
  >
  </q-table>
</template>

<script lang="coffee">
import { defineComponent, ref, onMounted, reactive, onUnmounted } from 'vue'
import { Metadata  } from "./metadataCls.coffee"
export default defineComponent({
  name: 'metadataView',
  props:
    instancename:
      type: String
      default: 'metadataView'
    displaycolumns:           # array of columns to display
      type: Array
      default: [
        { name: 'metadataId', label: 'Id', align: 'left', field: 'metadataId', sortable: true},
        { name: 'descriptorName', required: true,label: 'Name', align: 'left', field: 'descriptorName', sortable: true},
        { name: 'descriptorType',  label: 'Type', field: 'descriptorType',align: 'left', sortable: true },
        { name: 'displayValue', label: 'Value ', field: 'displayValue', sortable: true ,align: 'left'},
        { name: 'mandatory', label: 'Mandatory', field: 'mandatory' ,align: 'left', sortable: true},
        { name: 'scope', label: 'Source', field: 'scope' , sortable: true,align: 'left'},
        { name: 'inheritance', label: 'Inheritance Strategy', field: 'inheritance',align: 'left', sortable: true}
      ]
    visibleColumns:
      type: Array
      default:   [ 'descriptorName', 'descriptorType', 'displayValue', 'mandatory','scope','inheritance']
    resource:
      type: Object
      default: null

  setup: (props) ->
    metadata = reactive(new Metadata({props:props}))
    metadata.vueBinder()
    onMounted(() => metadata.mounted())
    onUnmounted(() => metadata.unmounted())
    return {metadata}

})
</script>
<style
  src="components/FileSystemBrowser/FileSystemBrowserStyle.sass"
  lang="sass"
/>
