<template>
  <div class="q-pa-md">
    <q-table
      class="sticky-header-table superdense"
      ref="resourceProperties"
      :rows="properties.records"
      :columns="properties.columns"
      row-key="caption"
      :rows-per-page-options="[0]"
    >
      <template v-slot:body-cell-value="props">
        <q-td :props="props">
          <div v-if="typeof props.row.value === 'boolean'">
            <q-icon
              size="xs"
              dense
              :name="props.row.value ? 'o_check_circle' : 'o_cancel'"
            />
          </div>
          <div v-else>
            {{ props.row.value }}
          </div>
        </q-td>
      </template>
      <template v-slot:bottom> </template>
    </q-table>
  </div>
</template>

<script lang="coffee">
  import { defineComponent, ref, onMounted, reactive, onUnmounted,watch } from 'vue'
  import { Properties  } from "./propertiesCls.coffee"
  export default defineComponent({
    name: 'properties',
    props:
      instancename:
        type: String
        default: 'properties'
      resource:
        type: Object
        default: null
      compareWith:
        type: Object
        default: null

        type: Number
      displayfields:
        type: Array
        default: ['resourceId', 'resourceVersionId','lockedAt', 'lockedByName','nodeType', 'name', 'parentId','deleted','entityId','entityVersionId',
          'revisionId','createdByName','createdById','createdAt','lastModifiedOn','lastModifiedById','lastModifiedByName',
          'fileSize','hasChildren','hasChildrenIncludingFiles','path','mimeType']
      captionMapping:
        type: Object
        default:
          resourceId:  'Resource Id'
          resourceVersionId: 'Resource Version Id'
          lockedById: 'Checked-out by Id'
          lockedByName: 'Checked-out by'
          lockedAt: 'Checked-out at'
          nodeType: 'Node Type'
          name: 'Name'
          parentId:'Parent Id'
          deleted:'Is Deleted'
          entityId: 'Entity Id'
          entityVersionId: 'Entity Version Id'
          revisionId: 'Revision Id'
          createdByName: 'Created By Name'
          createdById: 'Created by Id'
          createdAt: 'Created At'
          lastModifiedOn: 'Last Modified On'
          lastModifiedById: 'Last Modified by Id'
          lastModifiedByName: 'Last Modified by Name'
          fileSize: 'Size'
          hasChildren: 'Has Children'
          hasChildrenIncludingFiles: 'Has Children with Files'
          path: 'Path'
          mimeType: 'Mime Type'
    setup: (props) ->
      properties = reactive(new Properties({props:props}))
      properties.vueBinder()
      onMounted(() => properties.mounted())
      onUnmounted(() => properties.unmounted())
      watch( () =>
        props.resource
      ,(current,old) =>
        properties.resource=current
        properties.createRecords()
      ,{immediate:true}
      )


      return {properties}

  })
</script>
