<template>
  <div class="q-pa-md">
    <q-toolbar class="bg-primary text-white shadow-2">
      <q-toolbar-title>Improve Users</q-toolbar-title>
      <slot class="text-caption"></slot>
    </q-toolbar>
    <q-list>
      <q-slide-item
        v-for="user in userlist.userList"
        :key="user.id"
        @left="({ reset }) => userlist.onLeft(reset, user)"
        @right="({ reset }) => userlist.onRight(reset, user)"
        left-color="green"
        right-color="red"
      >
        <template v-if="userlist.swipeLeft" v-slot:left>
          <q-icon name="done" />
        </template>
        <template v-if="userlist.swipeRight" v-slot:right>
          <q-icon name="cancel" />
        </template>
        <q-item
          :clickable="userlist.clickable"
          v-ripple
          dense
          @click="$emit('userClicked', user)"
        >
          <q-item-section>
            <q-item-label>{{ user.name }}</q-item-label>
            <q-item-label caption lines="2"
              >{{ user.username }}<br />
              {{ user.email }}</q-item-label
            >
          </q-item-section>

          <q-item-section side top>
            <q-item-label caption>{{ user.role }}</q-item-label>
            <q-icon
              :name="user.active ? 'person' : 'person_off'"
              :color="user.active ? 'green' : 'red'"
            />
          </q-item-section>
        </q-item>
      </q-slide-item>
      <q-separator spaced inset />
    </q-list>
  </div>
</template>

<script lang="coffee">
  import { defineComponent, ref, onMounted, reactive, onUnmounted,watch } from 'vue'
  import { Userlist  } from "./userlistCls.coffee"
  export default defineComponent({
    emits: ['onApproved','onRejected','onSwipeLeft','onSwipeRight']
    name: 'userlist',
    props:
      instancename:
        type: String
        default: 'userlist'
      clickable:
        default:true
        type:Boolean
      swipeLeft:
        default:false
        type: Boolean
      swipeRight:
        default:false
        type: Boolean
      isUtil:
        default:true
        type:Boolean
    setup: (props,{ emit }) ->
      userlist = reactive(new Userlist({props:props,emit:emit}))
      userlist.vueBinder()
      onMounted(() => userlist.mounted())
      onUnmounted(() => userlist.unmounted())
      ###
      watch(() =>
        props.resource
      ,(current,old) =>
         userlist.resource=current
      ,{immediate:true}
      )
      ###
      return {userlist}

  })
</script>
