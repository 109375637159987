<template>
  <q-card flat class="resourceContent hide-scroll row q-ma-none q-pa-none">
    <q-card class="col resourceContent">
      <q-tabs
        v-model="reviewviewer.tab"
        dense
        :active-color="$q.dark.isActive ? 'white' : 'black'"
        align="justify"
        narrow-indicator
        inline-label
        class="text-grey-5 shadow-2"
        @update:model-value="reviewviewer.tabSwitched"
      >
        <q-tab name="users" label="User Assignement" icon="people" />
        <q-tab name="comments" label="Entries Overview" icon="comment" />
      </q-tabs>
      <q-separator />
      <div style="height: calc(100% - 36px); overflow-y: auto">
        <q-tab-panels v-model="reviewviewer.tab" animated keep-alive>
          <q-tab-panel name="comments" class="resourceTabs">
            <div class="row no-wrap">
              <div class="col">
                <q-list dense>
                  <!-- First Item is the review itself -->
                  <q-item
                    :dragable="false"
                    :key="reviewviewer.vueRefresh"
                    :active="reviewviewer.selectedEntry === resource.resourceId"
                    dense
                    clickable
                    class="text-bold"
                    @click="reviewviewer.entryItemClicked(resource)"
                  >
                    <q-item-section>
                      <q-item-label>{{
                        reviewviewer.resource.name
                      }}</q-item-label>
                      <q-item-label caption lines="2"
                        >Review Status:
                        {{ reviewviewer.resource.reviewStatus }}</q-item-label
                      >
                    </q-item-section>

                    <q-item-section side top>
                      <q-badge
                        rounded
                        color="primary"
                        :label="
                          'Comments: ' +
                          reviewviewer.resource.comments.length.toString()
                        "
                      />
                    </q-item-section>
                  </q-item>
                  <!-- Here are the entries -->
                  <q-item
                    :dragable="false"
                    dense
                    v-for="entry in reviewviewer.resource.realEntries"
                    :key="entry.id + entry.status"
                    :active="reviewviewer.selectedEntry === entry.id"
                    clickable
                    @click="reviewviewer.entryItemClicked(entry)"
                    @dblclick="reviewviewer.entryItemDblClicked(entry)"
                  >
                    <q-item-section
                      :style="reviewviewer.setPadding(entry.virtualPath)"
                      class="entryTreeItem"
                    >
                      <q-item-label v-if="entry.virtualParent.length"
                        >{{ entry.virtualParent }}{{ entry.name }}</q-item-label
                      >
                      <q-item-label v-else-if="entry.nodeType === 'Step'">
                        {{ entry.resource.path.split("/").slice(-2)[0] }}/{{
                          entry.name
                        }}
                      </q-item-label>
                      <q-item-label v-else>{{ entry.name }}</q-item-label>
                      <div class="row no-wrap">
                        <!-- when the entry finishedStatus is finishedInherited, then the entry was already approved/rejected in another review
                        so, the normal case is here-->
                        <q-item-label
                          v-if="entry.finishedStatus !== 'finishedInherited'"
                          caption
                          :class="
                            entry.status === 'Approved'
                              ? 'text-green q-ml-xs'
                              : entry.status === 'Reviewing'
                              ? 'text-primary q-ml-xs'
                              : 'text-red q-ml-xs'
                          "
                        >
                          Status: {{ entry.status }}
                        </q-item-label>
                        <!-- while the case when it was done elsewhere is here-->
                        <q-item-label
                          v-else
                          caption
                          class="text-purple q-ml-xs"
                        >
                          Status: approved and finished
                        </q-item-label>
                        <q-space />
                        <q-item-label caption class="q-ml-md">{{
                          entry.resource.path
                        }}</q-item-label>
                      </div>
                    </q-item-section>

                    <q-item-section side top>
                      <q-badge
                        rounded
                        :color="
                          entry.status === 'Approved'
                            ? 'green'
                            : entry.status === 'Reviewing'
                            ? 'primary'
                            : 'red'
                        "
                        :label="'Comments: ' + entry.comments.length.toString()"
                      />
                    </q-item-section>
                  </q-item>
                </q-list>
              </div>
            </div>
          </q-tab-panel>

          <q-tab-panel name="users" class="resourceTabs">
            <div>
              <div class="row text-caption">
                Requestor: {{ reviewviewer.requestor.name }} (Role:{{
                  reviewviewer.requestor.role
                }}), You: {{ reviewviewer.currentUserUsername }}/{{
                  reviewviewer.currentUserName
                }}
              </div>
              <div class="row" style="width: 100%">
                <userbrowser
                  v-if="reviewviewer.userIsRequestor"
                  class="col-6"
                  :swipeLeft="reviewviewer.userIsRequestor"
                  @onSwipeLeft="reviewviewer.globalUserAdded"
                >
                  <span v-if="reviewviewer.userIsRequestor">Drag invite▶</span>
                  <span v-else></span>
                </userbrowser>
                <div class="col-6 q-pa-md">
                  <q-toolbar class="bg-primary text-white shadow-2">
                    <q-toolbar-title>Reviewers</q-toolbar-title>
                  </q-toolbar>
                  <q-list>
                    <q-item
                      dense
                      v-for="reviewer in reviewviewer.resource.reviewers"
                      :key="reviewer.id"
                    >
                      <q-item-section>
                        <q-item-label>{{ reviewer.name }}</q-item-label>
                        <q-item-label caption lines="2"
                          >{{ reviewer.username }}<br />
                          {{ reviewer.email }}
                        </q-item-label>
                      </q-item-section>
                      <q-item-section side>
                        <q-btn
                          v-show="reviewviewer.userIsRequestor"
                          rounded
                          outline
                          label="Remove"
                          color="red"
                          icon="cancel"
                          size="xs"
                          @click="reviewviewer.removeReviewer(reviewer)"
                        />
                        <q-btn
                          v-show="
                            reviewer.username ===
                              reviewviewer.currentUserUsername &&
                            !reviewviewer.userIsRequestor &&
                            reviewer.status !== 'ACCEPTED'
                          "
                          rounded
                          outline
                          label="Accept"
                          color="green"
                          icon="done"
                          size="xs"
                          @click="reviewviewer.reviewerAccepts(reviewer)"
                        />
                        <q-btn
                          v-show="
                            reviewer.username ===
                              reviewviewer.currentUserUsername &&
                            !reviewviewer.userIsRequestor &&
                            reviewer.status !== 'DECLINED'
                          "
                          rounded
                          outline
                          label="Decline"
                          color="red"
                          icon="cancel"
                          size="xs"
                          @click="reviewviewer.reviewerDeclines(reviewer)"
                        />
                      </q-item-section>
                      <q-item-section side top>
                        <q-item-label caption>{{ reviewer.role }}</q-item-label>
                        <q-icon
                          :name="reviewviewer.userstatus[reviewer.status]"
                          :color="reviewer.active ? 'green' : 'red'"
                        />
                      </q-item-section>
                    </q-item>

                    <q-separator spaced inset />
                  </q-list>
                </div>
              </div>
            </div>
          </q-tab-panel>
        </q-tab-panels>
      </div>
    </q-card>
  </q-card>
</template>

<script lang="coffee">
  import { defineComponent, ref, onMounted, reactive, onUnmounted,watch } from 'vue'
  import { useQuasar } from 'quasar'
  import { Reviewviewer  } from "./reviewviewerCls.coffee"
  import commentchat from './commentchat.vue'
  import userbrowser from './userlist.vue'
  export default defineComponent({
    emits:[]
    components:{commentchat,userbrowser}
    name: 'reviewviewer',
    props:
      instancename:
        type: String
        default: 'reviewviewer'
      resource:
        type: Object
        default: null
      compareWith:
        type: Object
        default: null
    setup: (props,{emits}) ->
      q = useQuasar()
      refs={}
      reviewviewer = reactive(new Reviewviewer({props:props,emit:emits,refs:refs,quasar:q}))
      reviewviewer.vueBinder()
      onMounted(() => reviewviewer.mounted())
      onUnmounted(() => reviewviewer.unmounted())

      watch(() =>
        props.resource
      ,(current,old) =>
         reviewviewer.onResourceChanged(current)
      ,{immediate:true}
      )

      return {reviewviewer}

  })
</script>
<style scoped lang="sass">
  .resourceContent
    height: 100%
  .resourceTabs
    height: 95%
    overflow-y: auto
  body.body--dark
    .q-item--active
      background-color: #333

  .q-item--active
      background-color: #e0e0e0
</style>
