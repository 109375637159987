<template>
  <q-card flat class="resourceContent hide-scroll row q-ma-none q-pa-none">
    <div style="heigth: 100%; width: 99%">
      <q-tabs
        v-model="predatorviewer.tab"
        dense
        class="text-grey"
        active-color="primary"
        indicator-color="primary"
        align="justify"
        narrow-indicator
      >
        <q-tab name="creation" label="Report Creation" />
        <q-tab name="management" label="Report Management" />
      </q-tabs>
      <q-tab-panels v-model="predatorviewer.tab" animated keep-alive>
        <q-tab-panel name="creation">
          <q-banner class="bg-primary text-white" dense rounded inline-actions>
            <template v-slot:avatar>
              <q-icon name="mdi-google-downasaur" />
            </template>
            pREDATOR generating: {{ predatorviewer.reportTitle }}
            <template v-slot:action>
              <q-btn
                flat
                @click="() => predatorviewer.showHelp()"
                icon="mdi-help"
                color="white"
                label="Help"
              />
            </template>
          </q-banner>
          <q-stepper
            class="q-ma-md fullwidth-stepper"
            horizontal
            flat
            v-model="predatorviewer.step"
            ref="stepper"
            color="primary"
            animated
            keep-alive
          >
            <q-step
              :name="1"
              title="Template selection"
              :caption="predatorviewer.currTemplate"
              :color="predatorviewer.template ? 'green' : 'primary'"
              icon="playlist_add_check"
              :error="predatorviewer.template == null"
              :done="predatorviewer.step > 1"
            >
              <templateselector
                :templates="predatorviewer.templateConfigs"
                @selected="(row) => predatorviewer.setTemplate(row)"
              />
            </q-step>

            <q-step
              :name="2"
              title="Data upload"
              :caption="predatorviewer.numberOfDataFiles + ' files uploaded'"
              :color="
                predatorviewer.step < 2
                  ? 'primary'
                  : predatorviewer.step >= 2 &&
                    predatorviewer.numberOfDataFiles > 0
                  ? 'green'
                  : 'primary'
              "
              icon="cloud_upload"
              :error="
                predatorviewer.step > 2 && predatorviewer.numberOfDataFiles == 0
              "
              :done="predatorviewer.step > 2"
            >
              <datafolderview
                :resource="predatorviewer.currDataDir"
                :uploadfilter="predatorviewer.template.dsFileType"
                :instancename="
                  'PredatorDataFolder_' + predatorviewer.resource.resourceId
                "
              >
              </datafolderview>
            </q-step>

            <q-step
              :name="3"
              title="Data preview"
              :caption="predatorviewer.formDataPreview"
              icon="preview"
              :error="predatorviewer.step >= 3 && !predatorviewer.formOk"
              :color="
                predatorviewer.step < 3
                  ? 'primary'
                  : predatorviewer.step >= 3 && predatorviewer.formOk
                  ? 'green'
                  : 'red'
              "
              :done="predatorviewer.step > 3"
            >
              <blitz-form
                class="q-mt-md"
                v-model="predatorviewer.dataFormModel"
                :schema="predatorviewer.dataFormSchema"
                :columnCount="predatorviewer.template.formColumns"
                labelPosition="left"
                @update:modelValue="
                  (x, y) => predatorviewer.dataFormModelUpdated(x, y)
                "
              />
              <!--dataform
                :mandatories="predatorviewer.template.mandatories"
                v-model:rdr="predatorviewer.rdrNumber"
                v-model:ro="predatorviewer.roNumber"
                v-model:exp_name="predatorviewer.experimentName"
                v-model:exp_date="predatorviewer.experimentDate"
                v-model:resp_scientist="predatorviewer.respScientist"
                v-model:colab_scientist="predatorviewer.collabScientist"
                v-model:quality_test_run="predatorviewer.qualityTestRun"
                v-model:skipped="predatorviewer.previewMode"
              ></!--dataform -->
            </q-step>

            <q-step
              :name="4"
              title="Report generation"
              icon="settings"
              :error="
                predatorviewer.step >= 4 &&
                ((predatorviewer.processFinished &&
                  !predatorviewer.processOK) ||
                  (predatorviewer.processFinished && predatorviewer.reRun))
              "
              :color="
                predatorviewer.step < 4
                  ? 'primary'
                  : predatorviewer.step >= 4 &&
                    predatorviewer.processFinished &&
                    predatorviewer.processOK
                  ? 'green'
                  : predatorviewer.step == 4 &&
                    (predatorviewer.processState ==
                      'State: RUNNING, Result: OK' ||
                      predatorviewer.processState ==
                        'QUALITY CONTROL: State: RUNNING, Result: OK')
                  ? 'orange'
                  : predatorviewer.step >= 4 &&
                    ((predatorviewer.processFinished &&
                      !predatorviewer.processOK) ||
                      (predatorviewer.processFinished && predatorviewer.reRun))
                  ? 'red'
                  : 'primary'
              "
              :caption="predatorviewer.processState"
              :done="predatorviewer.step > 4"
            >
              <q-linear-progress
                :indeterminate="predatorviewer.showProgress"
                :value="0"
                color="primary"
                class="q-mt-sm"
              />
              <q-linear-progress
                :query="predatorviewer.showProgress"
                :value="0"
                color="orange"
                class="q-mt-sm"
              />

              <stepviewer
                v-show="!predatorviewer.ComponentHub.activePlugin.hideFrameWork"
                :resource="
                  predatorviewer.isQualityStep
                    ? predatorviewer.previewStep
                    : predatorviewer.executionStep
                "
                :instancename="
                  'PredatorExecutionStep_' + predatorviewer.resource.resourceId
                "
              />
            </q-step>
            <!--
        <q-step
          :name="5"
          title="Report revision"
          caption="Report revision"
          :error="
            predatorviewer.step >= 4 &&
            (!predatorviewer.processFinished || !predatorviewer.processOK)
          "
          :color="predatorviewer.step < 5 ? 'primary' : 'green'"
          icon="history"
        >
          <h3 v-if="predatorviewer.processResult === 'ERROR'">
            Your report was not created or created with errors.
          </h3>
          <h3 v-else>
            Your report was created successfully. It is displayed in the list
            above.
          </h3>
        </q-step>
        -->
            <template v-slot:navigation>
              <q-stepper-navigation>
                <q-btn
                  @click="predatorviewer.stepperNext(false)"
                  :disable="predatorviewer.disableContinue"
                  color="primary"
                  :label="
                    predatorviewer.step === 5
                      ? 'New Report'
                      : predatorviewer.step === 1
                      ? 'Start'
                      : predatorviewer.step === 4 &&
                        predatorviewer.processFinished
                      ? 'Create new Report'
                      : 'Continue'
                  "
                />
                <q-btn
                  v-if="
                    (predatorviewer.step > 1 && predatorviewer.step < 4) ||
                    (predatorviewer.step == 5 && !predatorviewer.reducedMode) ||
                    predatorviewer.reRun
                  "
                  flat
                  color="primary"
                  @click="$refs.stepper.previous()"
                  label="Back"
                  class="q-ml-sm"
                />
                <q-btn
                  v-if="predatorviewer.step === 1"
                  :disable="predatorviewer.disableContinue"
                  flat
                  color="primary"
                  @click="predatorviewer.stepperNext(true)"
                  label="Generate empty report"
                  class="q-ml-sm"
                />
              </q-stepper-navigation>
            </template>
          </q-stepper>
        </q-tab-panel>
        <q-tab-panel name="management">
          <reportfolderviewer
            v-if="predatorviewer.reportFolder !== null"
            :resource="predatorviewer.reportFolder"
            :instancename="
              'PredatorReportFolder_' + predatorviewer.reportFolder.resourceId
            "
            :highlight="predatorviewer.createdReportResourceId"
            :filter="predatorviewer.reportsFilter"
            :empty="!predatorviewer.previewMode"
          >
          </reportfolderviewer>
          <div v-else>
            <h3>Please select a template in the creation tab</h3>
            Please select a template in the report creation tab in order to
            generate the report list
          </div>
        </q-tab-panel>
      </q-tab-panels>
    </div>
  </q-card>
</template>

<script lang="coffee">
import { defineComponent, ref, onMounted, reactive, onUnmounted } from 'vue'
import { BlitzForm,BlitzListForm, BlitzTable } from 'blitzar'
import {useQuasar, openURL} from 'quasar'
import { PredatorFolder  } from "./rootfolderviewCls.coffee"
import templateselector from "../predatorWizard/templateselector.vue"
import dataform from "../predatorWizard/dataform.vue"
import datafolderview from "./datafolderview"
import stepviewer from   'plugins/improveweb/stepview/stepviewer.vue'
import folderviewer from 'plugins/improveweb/folderview/folderview.vue'
import reportfolderviewer from './reportfolderview.vue'
export default defineComponent({
  components:{templateselector,datafolderview,dataform,stepviewer,folderviewer,reportfolderviewer},
  name: 'predatorviewer',
  props:
    instancename:
      type: String
      default: 'predatorviewer'
    resource:
      type: Object
      default: null
    compareWith:
      type: Object
      default: null
  setup: (props) ->
    q = useQuasar()
    stepper = ref(null)
    refs={stepper}
    predatorviewer = reactive(new PredatorFolder({props:props,refs:refs,quasar:q}))
    onMounted(() => predatorviewer.mounted())
    onUnmounted(() => predatorviewer.unmounted())
    return {predatorviewer,openURL}

})
</script>
<style scoped lang="sass">
.fullwidth-stepper
  width: calc(100% - 10px)

.resourceContent
  height: 100%
  overflow-y: auto
</style>
