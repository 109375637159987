<template>
  <q-card flat class="resourceContent hide-scroll row q-ma-none q-pa-none">
    <div class="row" style="width: 99%">
      <q-uploader
        class="col q-mr-md"
        ref="uploader"
        :url="
          datafolderviewer.apiUrl +
          '/resources/' +
          datafolderviewer.resource.resourceId +
          '?comment=improveWeb'
        "
        :label="datafolderviewer.resource.name"
        method="POST"
        multiple
        style="width: 99%"
        :factory="datafolderviewer.factoryFn"
        @added="datafolderviewer.onAdded"
        @finish="datafolderviewer.onFinish"
        @failed="datafolderviewer.onFailed"
        @uploading="datafolderviewer.noop"
        @uploaded="datafolderviewer.noop"
        @rejected="datafolderviewer.rejectedUpload"
        :filter="(files) => files.filter(datafolderviewer.filterExtension)"
      >
        <template v-slot:header="scope">
          <div class="row no-wrap items-center q-pa-sm q-gutter-xs">
            <q-btn
              v-if="scope.queuedFiles.length > 0"
              icon="clear_all"
              @click="scope.removeQueuedFiles"
              round
              dense
              flat
            >
              <q-tooltip>Clear All</q-tooltip>
            </q-btn>

            <q-spinner v-if="scope.isUploading" class="q-uploader__spinner" />
            <div class="col">
              <div class="q-uploader__title">Drop Files here for uploading</div>
              <div class="q-uploader__subtitle">
                {{ scope.uploadSizeLabel }} / {{ scope.uploadProgressLabel }}
              </div>
            </div>

            <q-btn
              v-if="scope.canAddFiles"
              type="a"
              icon="add_box"
              round
              dense
              flat
            >
              <q-uploader-add-trigger />
              <q-tooltip>Pick Files</q-tooltip>
            </q-btn>
            <q-btn
              v-if="scope.canUpload"
              class="blinkOne"
              icon="cloud_upload"
              @click="scope.upload"
              round
              dense
              flat
            >
              <q-tooltip>Upload Files</q-tooltip>
            </q-btn>

            <q-btn
              v-if="scope.isUploading"
              icon="clear"
              @click="scope.abort"
              round
              dense
              flat
            >
              <q-tooltip>Abort Upload</q-tooltip>
            </q-btn>
          </div>
        </template>
        <template v-slot:list="scope">
          <q-list separator>
            <q-item v-for="file in scope.files" :key="file.name">
              <q-item-section>
                <q-item-label class="full-width ellipsis">
                  {{ file.name }}
                </q-item-label>

                <q-item-label caption>
                  Status: {{ file.__status }}
                </q-item-label>

                <q-item-label caption>
                  {{ file.__sizeLabel }} / {{ file.__progressLabel }}
                </q-item-label>
              </q-item-section>

              <q-item-section v-if="file.__img" thumbnail class="gt-xs">
                <img :src="file.__img.src" />
              </q-item-section>

              <q-item-section top side>
                <q-btn
                  class="gt-xs"
                  size="12px"
                  flat
                  dense
                  round
                  icon="delete"
                  @click="scope.removeFile(file)"
                />
              </q-item-section>
            </q-item>
          </q-list>
        </template>
      </q-uploader>
      <q-table
        class="col sticky-header-table superdense q-pr-md hide-scrollbar"
        title="Currently present DataFiles"
        separator="cell"
        :columns="datafolderviewer.columns"
        :rows="datafolderviewer.tdata"
        row-key="resourceId"
        :filter="datafolderviewer.filter"
        :visible-columns="['name', 'lastModifiedFmt', 'fileSizeFmt', 'callDel']"
        :sort-method="datafolderviewer.tableSort"
        binary-state-sort
        column-sort-order="ad"
        :pagination="datafolderviewer.pagination"
        :rows-per-page-options="[10, 20, 30, 40, 50, 0]"
      >
        <template v-slot:top-right>
          <q-input
            v-if="!datafolderviewer.reducedMode"
            borderless
            dense
            debounce="300"
            v-model="datafolderviewer.filter"
            placeholder="Search"
          >
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
          <q-space />
          <q-btn
            color="primary"
            flat
            round
            dense
            icon="refresh"
            @click="datafolderviewer.loadFolder"
          />
        </template>
        <template v-slot:body="props">
          <q-tr :props="props" :key="props.row['resourceId']">
            <q-td
              style="cursor: pointer"
              v-bind="$attrs"
              :props="props"
              v-for="col in props.cols"
              :col="col"
              :key="col.name"
              @click="
                (evt) => datafolderviewer.onRowClick(props.row, col.name, evt)
              "
            >
              {{ props.row[col.name] }}
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>
  </q-card>
</template>

<script lang="coffee">
import { defineComponent, ref, onMounted, reactive, onUnmounted,watch } from 'vue'
import {useQuasar} from 'quasar'
import { DataFolderviewer  } from "./datafolderviewCls.coffee"
export default defineComponent({
  name: 'folderviewer',
  props:
    instancename:
      type: String
      default: 'datafolderview'
    resource:
      type: Object
      default: null
    compareWith:
      type: Object
      default: null
    uploadfilter:
      type: String
      default: ""
  setup: (props) ->
    q = useQuasar()
    uploader = ref(null)
    refs={uploader}
    datafolderviewer = reactive(new DataFolderviewer({props:props,refs:refs,quasar:q}))
    datafolderviewer.vueBinder()
    onMounted(() => datafolderviewer.mounted())
    onUnmounted(() => datafolderviewer.unmounted())

    watch(() =>
      props.resource
    ,(current,old) =>
      datafolderviewer.resource = current
      datafolderviewer.loadFolder()
    ,{immediate:true}
    )

    return {datafolderviewer,uploader}

})
</script>
<style scoped lang="sass">
.sticky-header-table
    height: calc(100% - 4px)
    width: calc(100% - 10px)
.superdense
  .q-checkbox__inner
    font-size: 24px
.blinkOne
  animation: blinker-one 1s linear infinite
@keyframes blinker-one
  0%
    opacity: 0
</style>
