<template>
  <q-card flat class="resourceContent hide-scroll row q-ma-none q-pa-none">
    <q-uploader
      ref="uploader"
      :url="
        folderviewer.apiUrl +
        '/resources/' +
        folderviewer.resource.resourceId +
        '?comment=improveWeb'
      "
      label="Add Files"
      method="POST"
      multiple
      style="width: 99%"
      :factory="folderviewer.factoryFn"
      @added="folderviewer.onAdded"
      @finish="folderviewer.onFinish"
      @failed="folderviewer.onFailed"
      @uploading="folderviewer.noop"
      @uploaded="folderviewer.noop"
    >
      <template v-slot:header="scope">
        <div class="row no-wrap items-center q-pa-sm q-gutter-xs">
          <q-btn
            v-if="scope.queuedFiles.length > 0"
            icon="clear_all"
            @click="scope.removeQueuedFiles"
            round
            dense
            flat
          >
            <q-tooltip>Clear All</q-tooltip>
          </q-btn>

          <q-spinner v-if="scope.isUploading" class="q-uploader__spinner" />
          <div class="col">
            <div class="q-uploader__title">Upload your files</div>
            <div class="q-uploader__subtitle">
              {{ scope.uploadSizeLabel }} / {{ scope.uploadProgressLabel }}
            </div>
          </div>

          <q-btn
            v-if="scope.canAddFiles"
            type="a"
            icon="add_box"
            round
            dense
            flat
          >
            <q-uploader-add-trigger />
            <q-tooltip>Pick Files</q-tooltip>
          </q-btn>
          <q-btn
            v-if="scope.canUpload"
            icon="cloud_upload"
            @click="scope.upload"
            round
            dense
            flat
          >
            <q-tooltip>Upload Files</q-tooltip>
          </q-btn>

          <q-btn
            v-if="scope.isUploading"
            icon="clear"
            @click="scope.abort"
            round
            dense
            flat
          >
            <q-tooltip>Abort Upload</q-tooltip>
          </q-btn>
        </div>
      </template>
      <template v-slot:list="scope">
        <q-list separator>
          <q-item v-for="file in scope.files" :key="file.name">
            <q-item-section>
              <q-item-label class="full-width ellipsis">
                {{ file.name }}
              </q-item-label>

              <q-item-label caption> Status: {{ file.__status }} </q-item-label>

              <q-item-label caption>
                {{ file.__sizeLabel }} / {{ file.__progressLabel }}
              </q-item-label>
            </q-item-section>

            <q-item-section v-if="file.__img" thumbnail class="gt-xs">
              <img :src="file.__img.src" />
            </q-item-section>

            <q-item-section top side>
              <q-btn
                class="gt-xs"
                size="12px"
                flat
                dense
                round
                icon="delete"
                @click="scope.removeFile(file)"
              />
            </q-item-section>
          </q-item>
        </q-list>
      </template>
    </q-uploader>
    <q-table
      class="sticky-header-table superdense q-pr-md hide-scrollbar"
      :title="'Contents of ' + folderviewer.resource.name"
      separator="cell"
      :columns="folderviewer.columns"
      :rows="folderviewer.tdata"
      row-key="resourceId"
      :sort-method="folderviewer.tableSort"
      binary-state-sort
      column-sort-order="ad"
      :filter="folderviewer.filter"
      :visible-columns="[
        'name',
        'fileSizeFmt',
        'lastModifiedFmt',
        'callDel',
        'callLock',
      ]"
      :pagination="folderviewer.pagination"
      :rows-per-page-options="[10, 20, 30, 40, 50, 0]"
    >
      <template v-slot:top-right>
        <q-input
          borderless
          dense
          debounce="300"
          v-model="folderviewer.filter"
          placeholder="Search"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
        <q-space />
        <q-btn
          color="primary"
          flat
          round
          dense
          icon="refresh"
          @click="folderviewer.loadFolder"
        />
      </template>
      <template v-slot:body="props">
        <q-tr :props="props" :key="props.row['resourceId']">
          <q-td
            style="cursor: pointer"
            v-bind="$attrs"
            :props="props"
            v-for="col in props.cols"
            :col="col"
            :key="col.name"
            @click="(evt) => folderviewer.onRowClick(props.row, col.name, evt)"
          >
            {{ props.row[col.name] }}
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </q-card>
</template>

<script lang="coffee">
import { defineComponent, ref, onMounted, reactive, onUnmounted } from 'vue'
import {useQuasar} from 'quasar'
import { Folderviewer  } from "./folderviewCls.coffee"
export default defineComponent({
  name: 'folderviewer',
  props:
    instancename:
      type: String
      default: 'folderviewer'
    resource:
      type: Object
      default: null
    compareWith:
      type: Object
      default: null
  setup: (props) ->
    q = useQuasar()
    uploader = ref(null)
    refs={uploader}
    folderviewer = reactive(new Folderviewer({props:props,refs:refs,quasar:q}))
    folderviewer.vueBinder()
    onMounted(() => folderviewer.mounted())
    onUnmounted(() => folderviewer.unmounted())
    return {folderviewer,uploader}

})
</script>
<style scoped lang="sass">
.sticky-header-table
    height: calc(100% - 4px)
    width: calc(100% - 10px)
.superdense
  .q-checkbox__inner
    font-size: 24px
</style>
