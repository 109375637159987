<template>
  <div class="q-ma-md fullSize hide-scrollbar">
    <q-card v-if="skipped" class="q-pa-sm q-ma-md fullSize hide-scrollbar">
      <h3>Empty Report requested</h3>
      <h4>Step is being prepared, please be patient</h4>
    </q-card>
    <q-card v-else class="q-pa-sm q-ma-md fullSize hide-scrollbar">
      <q-card-section>
        <strong>Attributes: <slot></slot> </strong>
      </q-card-section>

      <q-input
        label="RDR Number"
        dense
        :standout="mandatories.indexOf('RDR Number') >= 0"
        v-model="dataform.rdrNumber"
        :rules="
          mandatories.indexOf('RDR Number') >= 0
            ? [(val) => !!val || 'Field is required to continue']
            : []
        "
        @update:model-value="$emit('update:rdr', $event)"
        :hint="
          mandatories.indexOf('RDR Number') >= 0
            ? 'This is a required field'
            : 'This field is optional'
        "
      >
        <template v-slot:prepend v-if="mandatories.indexOf('RDR Number') >= 0">
          <q-icon name="mdi-star-circle" />
        </template>
      </q-input>
      <q-input
        label="RO Number"
        :standout="mandatories.indexOf('RO Number') >= 0"
        dense
        v-model="dataform.roNumber"
        :rules="
          mandatories.indexOf('RO Number') >= 0
            ? [(val) => !!val || 'Field is required to continue']
            : []
        "
        @update:model-value="$emit('update:ro', $event)"
        :hint="
          mandatories.indexOf('RO Number') >= 0
            ? 'This is a required field'
            : 'This field is optional'
        "
      >
        <template v-slot:prepend v-if="mandatories.indexOf('RO Number') >= 0">
          <q-icon name="mdi-star-circle" />
        </template>
      </q-input>
      <q-input
        label="Experiment Name"
        :standout="mandatories.indexOf('Experiment Name') >= 0"
        dense
        v-model="dataform.experimentName"
        :rules="
          mandatories.indexOf('Experiment Name') >= 0
            ? [(val) => !!val || 'Field is required to continue']
            : []
        "
        @update:model-value="$emit('update:exp_name', $event)"
        :hint="
          mandatories.indexOf('Experiment Name') >= 0
            ? 'This is a required field'
            : 'This field is optional'
        "
      >
        <template
          v-slot:prepend
          v-if="mandatories.indexOf('Experiment Name') >= 0"
        >
          <q-icon name="mdi-star-circle" />
        </template>
      </q-input>
      <q-input
        label="Experiment Date"
        :standout="mandatories.indexOf('Experiment Date') >= 0"
        stack-label
        dense
        :hint="
          mandatories.indexOf('Experiment Date') >= 0
            ? 'Input: YYYY/MM/DD - This is a required field'
            : 'Input: YYYY/MM/DD - This field is optional'
        "
        mask="####/##/##"
        v-model="dataform.experimentDate"
        :rules="
          mandatories.indexOf('Experiment Date') >= 0
            ? [
                (val) => !!val || 'Field is required to continue',
                (val) => !isNaN(Date.parse(val)) || 'No valid Date',
              ]
            : [(val) => !isNaN(Date.parse(val)) || 'No valid Date']
        "
        @update:model-value="$emit('update:exp_date', $event)"
      >
        <template
          v-slot:prepend
          v-if="mandatories.indexOf('Experiment Date') >= 0"
        >
          <q-icon name="mdi-star-circle" />
        </template>
      </q-input>
      <q-input
        label="Responsible Scientist(s)"
        :standout="mandatories.indexOf('Responsible Scientist(s)') >= 0"
        dense
        v-model="dataform.respScientist"
        :rules="
          mandatories.indexOf('Responsible Scientist(s)') >= 0
            ? [(val) => !!val || 'Field is required to continue']
            : []
        "
        @update:model-value="$emit('update:resp_scientist', $event)"
        :hint="
          mandatories.indexOf('Responsible Scientist(s)') >= 0
            ? 'This is a required field, comma seperated when more than one'
            : 'This field is optional, comma seperated when more than one'
        "
      >
        <template
          v-slot:prepend
          v-if="mandatories.indexOf('Responsible Scientist(s)') >= 0"
        >
          <q-icon name="mdi-star-circle" />
        </template>
      </q-input>
      <q-input
        label="Collaborating Scientist(s)"
        :standout="mandatories.indexOf('Collaborating Scientist(s)') >= 0"
        dense
        v-model="dataform.collabScientist"
        :rules="
          mandatories.indexOf('Collaborating Scientist(s)') >= 0
            ? [(val) => !!val || 'Field is required to continue']
            : []
        "
        @update:model-value="$emit('update:colab_scientist', $event)"
        :hint="
          mandatories.indexOf('Collaborating Scientist(s)') >= 0
            ? 'This is a required field, comma seperated when more than one'
            : 'This field is optional, comma seperated when more than one'
        "
      >
        <template
          v-slot:prepend
          v-if="mandatories.indexOf('Collaborating Scientist(s)') >= 0"
        >
          <q-icon name="mdi-star-circle" />
        </template>
      </q-input>
      <q-toggle
        label="Quality control"
        dense
        hint="When checked, then a quality test run is performed before the actual report is generated"
        v-model="dataform.qualityTestRun"
        @update:model-value="$emit('update:quality_test_run', $event)"
      ></q-toggle>
    </q-card>
  </div>
</template>

<script lang="coffee">
import { defineComponent, ref, onMounted, reactive, onUnmounted,watch } from 'vue'
import { useQuasar } from 'quasar'
import { Dataform  } from "./dataformCls.coffee"
export default defineComponent({
  emits:['update:colab_scientist','update:rdr','update:ro','update:resp_scientist','update:exp_date','update:exp_name','update:quality_test_run']
  name: 'dataform',
  props:
    instancename:
      type: String
      default: 'dataform'
    title:
      type: String
      default: ''
    rdr:
      type: String
      default: ''
    ro:
      type: String
      default: ''
    exp_name:
      type: String
      default: ''
    exp_date:
      type: String
      default: ''
    resp_scientist:
      type: String
      default: ''
    colab_scientist:
      type: String
      default: ''
    skipped:
      type: Boolean
      default: false
    quality_test_run:
      type: Boolean
      default: false
    mandatories:
      type: Array
      default: []

  setup: (props,{emits}) ->
    q = useQuasar()
    refs={}
    console.log
    dataform = reactive(new Dataform({props:props,emit:emits,refs:refs,quasar:q}))
    dataform.vueBinder()
    onMounted(() => dataform.mounted())
    onUnmounted(() => dataform.unmounted())

    watch(() =>
      props.rdr
    ,(current,old) =>
       dataform.rdrNumber=current
    ,{immediate:true}
    )
    watch(() =>
      props.ro
    ,(current,old) =>
       dataform.roNumber=current
    ,{immediate:true}
    )
    watch(() =>
      props.exp_name
    ,(current,old) =>
       dataform.experimentName=current
    ,{immediate:true}
    )
    watch(() =>
      props.exp_date
    ,(current,old) =>
       dataform.experimentDate=current
    ,{immediate:true}
    )
    watch(() =>
      props.resp_scientist
    ,(current,old) =>
       dataform.respScientist=current
    ,{immediate:true}
    )
    watch(() =>
      props.colab_scientist
    ,(current,old) =>
       dataform.collabScientist=current
    ,{immediate:true}
    )
    return {dataform}

})
</script>
<style scoped lang="sass">
.fullSize
  height: 100%
  width:100%
  overflow-y: scroll
.smart-error
  color: orange
</style>
