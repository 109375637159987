<template>
  <div class="q-mr-sm q-ml-sm">
    <div v-if="resource" class="text-bold">
      Comments for <span v-if="iwebtype === 'ImproveReview'"> Review</span
      ><span v-else>{{ resource.getHumanReadableVersion() }} </span>
    </div>
    <div v-if="commentchat.resource">
      {{ commentchat.resource.name.slice(0, 32) }}
      <span v-if="commentchat.resource.name.length > 32"> ...</span>
    </div>
    <div v-if="commentchat.resource.iWebType === 'ImproveReview'">
      <q-toggle
        dense
        v-model="commentchat.aggregate"
        @update:model-value="commentchat.getMessages"
        label="Aggregate comments of all review entries"
      />
    </div>
    <q-toggle
      dense
      v-model="commentchat.revisionOnly"
      @update:model-value="commentchat.getMessages"
      label="Show only comments from current revison"
    />
    <q-input
      bottom-slots
      v-model="commentchat.chatText"
      counter
      maxlength="256"
      dense
      @keydown.enter.prevent="commentchat.addComment"
    >
      <template v-slot:hint> Enter your comment here </template>

      <template v-slot:after>
        <q-btn round dense flat icon="send" @click="commentchat.addComment" />
      </template>
    </q-input>
    <div class="innerContainer chatBox" :style="commentchat.cssVars">
      <q-chat-message
        v-for="message in commentchat.messages"
        :name="
          commentchat.aggregate
            ? message.createdByName + ' on ' + message.resourceName
            : message.createdByName
        "
        :text="[message.comment]"
        :sent="message.sent"
        :class="message.sent ? 'q-ml-md' : ''"
        :stamp="new Date(parseInt(message.createdAt)).toLocaleString()"
      />
    </div>
  </div>
</template>

<script lang="coffee">
  import { defineComponent, ref, onMounted, reactive, onUnmounted,watch } from 'vue'
  import { Commentchat  } from "./commentchatCls.coffee"
  export default defineComponent({
    name: 'commentchat',
    props:
      instancename:
        type: String
        default: 'commentchat'
      iwebtype:
        default: 'ImproveReview'
        type: String
      resource:
        default:
          comments:[]
        type: Object
      revisionOnly:
        default:false
        type:Boolean
      isUtil:
        default:true
        type:Boolean
    setup: (props) ->
      commentchat = reactive(new Commentchat({props:props}))
      commentchat.vueBinder()
      onMounted(() => commentchat.mounted())
      onUnmounted(() => commentchat.unmounted())

      watch(() =>
        props.resource
      ,(current,old) =>
         commentchat.resource= current
         commentchat.iwebtype= current.iWebType

         commentchat.getMessages()
      ,{immediate:true}
      )

      return {commentchat}

  })
</script>
<style lang="sass">
  .chatBox
    height: var(--chatHeight)
    width: 100%
    overflow-y: auto
</style>
