<template>
  <q-card flat class="resourceContent hide-scroll row q-ma-none q-pa-none">
    <q-banner dense class="bg-transparent text-primary">
      <q-btn
        outline
        class="q-mr-lg"
        color="primary"
        size="35px"
        round
        :icon="noneviewer.icon"
      />
      <span v-if="resource">
        <strong>{{ resource.name }}</strong> {{ resource.fileSize }} Bytes,
        modified: {{ resource.lastModifiedFmt }}
      </span>
      <span v-else>
        <strong>No Selection </strong>
      </span>
      <template v-slot:action>
        <q-btn flat color="primary" label="Download" icon="download" />
      </template>
    </q-banner>
  </q-card>
</template>

<script lang="coffee">
import { defineComponent, ref, onMounted, reactive, onUnmounted } from 'vue'
import { Noneviewer  } from "./noneviewerCls.coffee"
export default defineComponent({
  name: 'noneviewer',
  props:
    instancename:
      type: String
      default: 'noneviewer'
    resource:
      type: Object
      default: null
    compareWith:
      type: Object
      default: null
  setup: (props) ->
    noneviewer = reactive(new Noneviewer({props:props}))
    noneviewer.vueBinder()
    onMounted(() => noneviewer.mounted())
    onUnmounted(() => noneviewer.unmounted())
    return {noneviewer}

})
</script>
