<template>
  <q-card class="row no-wrap fullSize">
    <q-card class="col">
      <div v-if="sheetviewer.showContent">
        <q-table
          class="superdense q-pr-md hide-scrollbar"
          separator="cell"
          :filter="sheetviewer.filter"
          :rows="sheetviewer.sheetData"
          row-key="name"
          :pagination="sheetviewer.pagination"
          :rows-per-page-options="[10, 20, 30, 40, 50, 0]"
        >
          <template v-slot:top-right>
            <q-input
              borderless
              dense
              debounce="300"
              v-model="sheetviewer.filter"
              placeholder="Search"
            >
              <template v-slot:append>
                <q-icon name="search" />
              </template>
            </q-input>
          </template>
        </q-table>
      </div>
    </q-card>
    <q-card v-show="compareWith != null" class="col q-ml-md">
      <div v-if="sheetviewer.showCompareContent">
        <q-table
          :rows="sheetviewer.sheetDataCompare"
          row-key="name"
          :filter="sheetviewer.filter"
          class="superdense q-pr-md hide-scrollbar"
          separator="cell"
          :pagination="sheetviewer.pagination"
          :rows-per-page-options="[10, 20, 30, 40, 50, 0]"
        >
          <template v-slot:top-right>
            <q-input
              borderless
              dense
              debounce="300"
              v-model="sheetviewer.filter"
              placeholder="Search"
            >
              <template v-slot:append>
                <q-icon name="search" />
              </template>
            </q-input>
          </template>
        </q-table>
      </div>
    </q-card>
  </q-card>
</template>

<script lang="coffee">
  import { defineComponent, ref, onMounted, reactive, onUnmounted,watch } from 'vue'
  import { useQuasar } from 'quasar'
  import { Sheetviewer  } from "./sheetviewerCls.coffee"
  export default defineComponent({
    emits:[]
    name: 'sheetviewer',
    props:
      instancename:
        type: String
        default: 'sheetviewer'
      file:
        type: Object
        default:null
      resource:
        type: Object
        default:null
      compareWith:
        type: Object
        default: null
    setup: (props,{emits}) ->
      q = useQuasar()
      refs={}
      sheetviewer = reactive(new Sheetviewer({props:props,emit:emits,refs:refs,quasar:q}))
      sheetviewer.vueBinder()
      onMounted(() => sheetviewer.mounted())
      onUnmounted(() => sheetviewer.unmounted())

      watch(() =>
        props.resource
      ,(current,old) =>
        sheetviewer.changeRevision(current)
      ,{immediate:true}
      )

      watch(() =>
        props.compareWith
      ,(current,old) =>
        sheetviewer.changeCompare(current)
      ,{immediate:true}
      )
      return {sheetviewer}

  })
</script>
