<template>
  <q-card flat class="resourceContent hide-scroll q-ma-none q-pa-none">
    <q-table
      style="height: 400px"
      class="superdense q-mb-lg q-pr-md hide-scrollbar"
      title="Generated Reports"
      separator="cell"
      :columns="reportfolderviewer.columns"
      :rows="reportfolderviewer.tdata"
      row-key="resourceId"
      :filter="reportfolderviewer.combinedFilter"
      :filterMethod="reportfolderviewer.filterRows"
      :visible-columns="reportfolderviewer.visibleReportColumns"
      :sort-method="reportfolderviewer.tableSort"
      binary-state-sort
      column-sort-order="ad"
      :pagination="reportfolderviewer.pagination"
      :rows-per-page-options="[0]"
      @row-click="reportfolderviewer.onRowClick"
    >
      <template v-slot:top-right>
        <q-toggle
          class="q-mr-sm"
          dense
          v-model="reportfolderviewer.filterOutEmpty"
          label="Hide Empty Reports"
        />
        <q-toggle
          class="q-mr-sm"
          dense
          v-model="reportfolderviewer.filterLock"
          label="Only locked"
        />
        <q-input
          class="q-mr-sm"
          label="created or mod. from (incl.)"
          stack-label
          dense
          hint="Format: YYYY/MM/DD"
          mask="####/##/##"
          v-model="reportfolderviewer.filterDateFrom"
          clearable
          :rules="[
            (val) =>
              !isNaN(Date.parse(val) || val.lenght === 0) || 'No valid Date',
          ]"
        />
        <q-input
          class="q-mr-sm"
          label="created before (incl.)"
          stack-label
          dense
          hint="Format: YYYY/MM/DD"
          mask="####/##/##"
          v-model="reportfolderviewer.filterDateTo"
          clearable
          :rules="[
            (val) =>
              !isNaN(Date.parse(val) || val.lenght === 0) || 'No valid Date',
          ]"
        />

        <q-input
          class="q-mr-sm"
          borderless
          dense
          debounce="300"
          v-model="reportfolderviewer.filter"
          placeholder="Full Table Search"
          clearable
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
        <q-space />
        <q-btn
          color="primary"
          flat
          round
          dense
          icon="refresh"
          @click="reportfolderviewer.loadFolder"
        />
        <!--
        <q-select
          v-model="reportfolderviewer.visibleReportColumns"
          multiple
          borderless
          dense
          options-dense
          display-value="⋮"
          emit-value
          map-options
          :options="reportfolderviewer.allowedReportColumns"
          option-value="name"
          options-cover
          style="max-width: 50px"
        />
        -->
      </template>
      <template v-slot:body="props">
        <q-tr
          :props="props"
          :key="props.row['resourceId']"
          :class="
            props.row['resourceId'] == reportfolderviewer.highlight
              ? 'highlight'
              : ''
          "
        >
          <q-td
            style="cursor: pointer"
            v-bind="$attrs"
            :props="props"
            v-for="col in props.cols"
            :col="col"
            :key="col.name"
            @click="
              (evt) => reportfolderviewer.onRowClick(props.row, col.name, evt)
            "
          >
            <q-icon
              v-if="col.name === 'downloadPackage' || col.name === 'callDel'"
              :name="props.row[col.name]"
              size="20px"
              tag="span"
            />
            <span v-else-if="col.name === 'callLock'">
              <q-icon
                :name="props.row[col.name]"
                size="20px"
                tag="span"
              ></q-icon>
              {{ props.row["lockdBy"] }}
            </span>
            <span v-else> {{ props.row[col.name] }}</span>
          </q-td>
        </q-tr>
      </template>
    </q-table>
    <div class="col-12" style="text-align: right">
      Locked: <q-icon name="mdi-lock" size="1em" /> Unlocked:
      <q-icon name="mdi-lock-open-outline" size="1em" /> Download Datasource:
      <q-icon name="mdi-folder-download-outline" size="1em" /> Download File:
      <q-icon name="mdi-file-download-outline" size="1em" /> Delete:
      <q-icon name="mdi-delete" size="1em" /> Not applicable:
      <q-icon name="mdi-circle-off-outline" size="1em" />
    </div>
    <q-table
      style="height: 400px"
      class="superdense q-pr-md hide-scrollbar"
      separator="cell"
      title="Version History"
      :columns="reportfolderviewer.revColumns"
      :rows="reportfolderviewer.revisions"
      :visible-columns="reportfolderviewer.visibleRevisionColumns"
      row-key="resourceId"
      binary-state-sort
      column-sort-order="ad"
      :pagination="reportfolderviewer.pagination"
      :rows-per-page-options="[0]"
      @row-click="reportfolderviewer.onVersionRowClick"
    >
      <template v-slot:top-right>
        <q-file
          v-show="reportfolderviewer.showRevisionUpload"
          class="q-ml-md"
          dense
          :max-files="1"
          label="Select file..."
          hint="Drop .docx to upload or Click to select"
          style="min-width: 250px"
          v-model="reportfolderviewer.uploadfile"
          :filter="
            (files) =>
              files.filter((file) => file.name.toLowerCase().endsWith('.docx'))
          "
          clearable
          @rejected="reportfolderviewer.rejectedUpload"
          @update:model-value="reportfolderviewer.uploadnewversion"
        >
        </q-file>
        <!--
        <q-select
          v-model="reportfolderviewer.visibleRevisionColumns"
          multiple
          borderless
          dense
          options-dense
          display-value="⋮"
          emit-value
          map-options
          :options="reportfolderviewer.revColumns"
          option-value="name"
          options-cover
          style="max-width: 50px"
        />
        -->
      </template>

      <template v-slot:body="props">
        <q-tr
          :props="props"
          :key="props.row['version']"
          :style="props.row['disabled'] ? 'color:grey;' : ''"
        >
          <q-td
            style="cursor: pointer"
            v-bind="$attrs"
            :props="props"
            v-for="col in props.cols"
            :col="col"
            :key="col.name"
            @click="
              (evt) =>
                reportfolderviewer.onVersionRowClick(props.row, col.name, evt)
            "
          >
            <q-icon
              v-if="col.name === 'downloadVersion' || col.name === 'callDel'"
              :name="props.row[col.name]"
              size="20px"
              label=""
            />
            <span v-else>{{ props.row[col.name] }}</span>
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </q-card>
</template>

<script lang="coffee">
import { defineComponent, ref, onMounted, reactive, onUnmounted,watch } from 'vue'
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import {useQuasar} from 'quasar'
import { ReportFolderviewer  } from "./reportfolderviewCls.coffee"
export default defineComponent({
  name: 'ReportFolderviewer',
  components: {Splitpanes, Pane}
  props:
    instancename:
      type: String
      default: 'ReportFolderviewer'
    resource:
      type: Object
      default: null
    compareWith:
      type: Object
      default: null
    highlight:
      type: String
      default: ""
    filter:
      type: String
      default: ""
    empty:
      type: Boolean
      default: ""
  setup: (props) ->
    q = useQuasar()
    uploader = ref(null)
    refs={uploader}
    reportfolderviewer = reactive(new ReportFolderviewer({props:props,refs:refs,quasar:q}))
    reportfolderviewer.vueBinder()
    onMounted(() => reportfolderviewer.mounted())
    onUnmounted(() => reportfolderviewer.unmounted())

    watch(() =>
      props.resource
    ,(current,old) =>
       reportfolderviewer.resource=current
       reportfolderviewer.loadFolder()
    ,{immediate:true}
    )
    watch(() =>
      props.highlight
    ,(current,old) =>
       reportfolderviewer.highlight=current
    ,{immediate:true}
    )
    watch(() =>
      props.filter
    ,(current,old) =>
       reportfolderviewer.filter=current
    ,{immediate:true}
    )
    watch(() =>
      props.empty
    ,(current,old) =>
       reportfolderviewer.filterOutEmpty=current
    ,{immediate:true}
    )

    return {reportfolderviewer}

})
</script>
<style scoped lang="sass">
.sticky-header-table
    height: calc(100% - 4px)
    width: calc(100% - 10px)
.highlight
  background-color: #1976d2
</style>
