<template>
  <q-table
    style="height: 400px"
    class="sticky-header-table superdense q-pr-md hide-scrollbar"
    title="List of Templates"
    separator="cell"
    :columns="templateselector.columns"
    :rows="templateselector.tdata"
    row-key="name"
    selection="single"
    @selection="templateselector.onSelection"
    v-model:selected="templateselector.selection"
    :rows-per-page-options="[0]"
    :pagination="templateselector.pagination"
    :visible-columns="[
      'folder',
      'folderId',
      'name',
      'version',
      'dsName',
      'dsFileType',
    ]"
  >
  </q-table>
</template>

<script lang="coffee">
import { defineComponent, ref, onMounted, reactive, onUnmounted,watch } from 'vue'
import { useQuasar } from 'quasar'
import { Templateselector  } from "./templateselectorCls.coffee"
export default defineComponent({
  emits:['selected']
  name: 'templateselector',
  props:
    instancename:
      type: String
      default: 'templateselector'
    templates:
      type: Array
      default: []

  setup: (props,{emit}) ->
    q = useQuasar()
    refs={}

    templateselector = reactive(new Templateselector({props:props,emit:emit,refs:refs,quasar:q}))
    templateselector.vueBinder()
    onMounted(() => templateselector.mounted())
    onUnmounted(() => templateselector.unmounted())

    watch(() =>
      props.templates
    ,(current,old) =>
       templateselector.templates=current
       templateselector.setTemplates()
    ,{immediate:true, deep: true}
    )


    return {templateselector}

})
</script>
<style scoped lang="sass">
.sticky-header-table
    height: calc(100% - 4px)
    width: calc(100% - 10px)
.superdense
  .q-checkbox__inner
    font-size: 24px
</style>
