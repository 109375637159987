<template>
  <q-banner
    dense
    :class="
      resource.status === 'Approved'
        ? 'bg-transparent text-green'
        : resource.status === 'Reviewing'
        ? 'bg-transparent text-primary'
        : 'bg-transparent text-red'
    "
    inline-actions
  >
    {{ resource.status }}
    <template v-slot:action>
      <q-btn
        flat
        color="positive"
        label="Approve"
        icon="mdi-check-circle-outline"
        @click="approvalbar.onApprove"
        :disable="
          approvalbar.resource.disableApproveButtonForCurrentUser ||
          approvalbar.resource.iWebType === 'ImproveReview' ||
          approvalbar.resource.inReview.reviewStatus !== 'Reviewing' ||
          approvalbar.resource.finishedStatus !== 'unfinished'
        "
      />
      <q-btn
        flat
        color="negative"
        label="Reject"
        icon="mdi-close-circle-outline"
        @click="approvalbar.onReject"
        :disable="
          approvalbar.resource.disableRejectButtonForCurrentUser ||
          approvalbar.resource.iWebType === 'ImproveReview' ||
          approvalbar.resource.inReview.reviewStatus !== 'Reviewing' ||
          approvalbar.resource.finishedStatus !== 'unfinished'
        "
      />
      <q-toggle
        v-if="approvalbar.resource.isFolderish"
        dense
        v-model="approvalbar.processChildren"
        label="incl. children"
      />
    </template>
  </q-banner>
</template>

<script lang="coffee">
  import { defineComponent, ref, onMounted, reactive, onUnmounted,watch } from 'vue'
  import { Approvalbar  } from "./approvalbarCls.coffee"
  export default defineComponent({
    emits: ['onApproved','onRejected']
    name: 'approvalbar',
    props:
      instancename:
        type: String
        default: 'approvalbar'
      resource:
        type: Object
        default: {name: 'No Selection'}
      isUtil:
        default:true
        type:Boolean
    setup: (props,{ emit }) ->
      approvalbar = reactive(new Approvalbar({props:props,emit:emit}))
      approvalbar.vueBinder()
      onMounted(() => approvalbar.mounted())
      onUnmounted(() => approvalbar.unmounted())
      ###
      watch(() =>
        props.resource
      ,(current,old) =>
         approvalbar.resource=current
      ,{immediate:true}
      )
      ###
      return {approvalbar}

  })
</script>
<style scoped lang="sass">
  .q-btn.disabled
    opacity: 0.7 !important
    color: grey !important
</style>
