<template>
  <q-card q-card="row no-wrap">
    <q-card-section class="q-ma-none q-pa-none">
      <q-card flat class="resourceContent hide-scroll row q-ma-none q-pa-none">
        <q-card class="col">
          <q-img
            v-if="imageviewer.showContent"
            :src="imageviewer.imageData"
            native-context-menu
            spinner-color="white"
            loading="lazy"
            fit="fill"
          >
            <template v-slot:error>
              <div
                class="absolute-full flex flex-center bg-negative text-white"
              >
                Cannot load image
              </div>
            </template>
          </q-img>
          <div v-else class="row justify-center q-mt-lg q-mb-lg">
            <q-spinner color="primary" size="3em" :thickness="10" />
          </div>
        </q-card>
        <q-card v-show="compareWith != null" class="col q-ml-md">
          <q-img
            v-if="imageviewer.showCompareContent"
            :src="imageviewer.imageCompareData"
            native-context-menu
            spinner-color="white"
            loading="lazy"
            fit="fill"
          >
            <template v-slot:error>
              <div
                class="absolute-full flex flex-center bg-negative text-white"
              >
                Cannot load compare image
              </div>
            </template>
          </q-img>
          <div v-else class="row justify-center q-mt-lg q-mb-lg">
            <q-spinner color="primary" size="3em" :thickness="10" />
          </div>
        </q-card>
      </q-card>
    </q-card-section>
  </q-card>
</template>

<script lang="coffee">
  import { defineComponent, ref, onMounted, reactive, onUnmounted, watch } from 'vue'
  import { Imageviewer  } from "./imageviewerCls.coffee"
  export default defineComponent({
    name: 'imageviewer',
    props:
      instancename:
        type: String
        default: 'imageviewer'
      file:
        type: Object
        default: null
      resource:
        type: Object
        default: null
      compareWith:
        type: Object
        default: null
    setup: (props) ->
      imageviewer = reactive(new Imageviewer({props:props}))
      imageviewer.vueBinder()
      onMounted(() => imageviewer.mounted())
      onUnmounted(() => imageviewer.unmounted())

      watch(() =>
        props.resource
      ,(current,old) =>
        imageviewer.changeRevision(current)
      ,{immediate:true}
      )

      watch(() =>
        props.compareWith
      ,(current,old) =>
        imageviewer.changeCompare(current)
      ,{immediate:true}
      )

      return {imageviewer}

  })
</script>
