<template>
  <!-- <q-card flat class="resourceContent hide-scroll row q-ma-none q-pa-none"> -->
  <q-card class="row no-wrap fullSize">
    <div class="StepContainer">
      <div class="col">
        <q-tabs
          v-model="stepviewer.tab"
          dense
          class="text-grey"
          active-color="primary"
          indicator-color="primary"
          align="justify"
          narrow-indicator
        >
          <q-tab name="properties" label="Step Properties" />
          <q-tab name="folder" label="Folder Contents" />
        </q-tabs>

        <q-separator />

        <q-tab-panels v-model="stepviewer.tab" animated>
          <q-tab-panel name="properties">
            <q-splitter
              class="col-grow self-stretch q-mt-md q-mb-md"
              v-model="stepviewer.splitterModel"
              separator-style="width: 1px"
            >
              <template v-slot:before>
                <q-table
                  class="superdense hide-scroll"
                  :rows="stepviewer.resource.processes"
                  :columns="stepviewer.columns"
                  :rows-per-page-options="[0]"
                  @row-click="stepviewer.processRowClicked"
                >
                  <template v-slot:top-right>
                    <q-btn
                      color="primary"
                      flat
                      round
                      dense
                      icon="refresh"
                      @click="stepviewer.refreshStep"
                    />
                  </template>
                </q-table>
              </template>

              <template v-slot:separator>
                <q-avatar
                  color="primary"
                  text-color="white"
                  size="20px"
                  icon="drag_indicator"
                />
              </template>
              <template v-slot:after>
                <properties
                  :objecttodisplay="stepviewer.selectedProcess"
                  :mapping="stepviewer.processProperties"
                  :groups="stepviewer.processPropertyGroups"
                >
                </properties>
              </template>
            </q-splitter>
          </q-tab-panel>

          <q-tab-panel name="folder">
            <folderviewer
              :resource="resource"
              :compareWith="compareWith"
              toolBarName="mainResourceActionBar"
            />
          </q-tab-panel>
        </q-tab-panels>
      </div>
    </div>
  </q-card>
</template>

<script lang="coffee">
import { defineComponent, ref, onMounted, reactive, onUnmounted,watch } from 'vue'
import { Stepviewer  } from "./stepviewerCls.coffee"
import properties from '../properties/propertygrid.vue'
import folderviewer from '../folderview/folderview.vue'

export default defineComponent({
  components:{properties,folderviewer}
  name: 'stepviewer',
  props:
    instancename:
      type: String
      default: 'stepviewer'
    resource:
      type: Object
      default: null
    compareWith:
      type: Object
      default: null
  setup: (props) ->
    stepviewer = reactive(new Stepviewer({props:props}))
    stepviewer.vueBinder()
    onMounted(() => stepviewer.mounted())
    onUnmounted(() => stepviewer.unmounted())

    watch(() =>
      props.resource
    ,(current,old) =>
      stepviewer.loadStep(current)
      stepviewer.refreshStep()
    ,{immediate:true}
    )



    return {stepviewer}

})
</script>
<style lang="sass">
.StepContainer
    display: flex
    width: 100%
    height: 100%
    flex-direction: column
    overflow: scroll
.fullSize

    width: 100%
    height: 100%
</style>
