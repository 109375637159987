<template>
  <div class="col-12 bg-transparent q-ml-md" style="max-width=100%">
    <div class="row no-wrap">
      <div class="col-1 col-md-auto">
        Revisions:
        <q-tooltip>
          Left Click to select a revision, right-click to compare revision with
          currently selected revision
        </q-tooltip>
      </div>
      <div class="col q-mr-md">
        <q-tabs
          v-model="revisionbar.tabDummy"
          no-caps
          dense
          outside-arrows
          mobile-arrows
          align="left"
          class="bg-transparent"
          inline-label
          switch-indicator
        >
          <q-tab
            v-for="ver in revisionbar.revisions"
            :class="'text-' + revisionbar.getColor(ver)"
            :alert="revisionbar.getIcon(ver).length > 0 ? true : false"
            :alert-icon="revisionbar.getIcon(ver)"
            :name="ver.resourceVersionId"
            :label="ver.getHumanReadableVersion()"
            @click="revisionbar.onClick(ver)"
            @contextmenu.prevent="revisionbar.onRightClick(ver)"
          >
          </q-tab>
        </q-tabs>
      </div>
    </div>
  </div>
</template>

<script lang="coffee">
import { defineComponent, ref, onMounted, reactive, onUnmounted } from 'vue'
import { Revisionbar  } from "./revisionbarCls.coffee"
export default defineComponent({
  emits: ['onRevisionChanged']
  name: 'revisionbar',
  props:
    instancename:
      type: String
      default: 'revisionbar'
    resource:
      type: Object
      default: {name: 'No Selection'}
    targetResource:
      default: null
      type: Object
  setup: (props, {emit}) ->
    revisionbar = reactive(new Revisionbar({props:props,emit:emit}))
    revisionbar.vueBinder()
    onMounted(() => revisionbar.mounted())
    onUnmounted(() => revisionbar.unmounted())
    return {revisionbar}

})
</script>
<style scoped lang="sass">
.i-history
  font-size: 12px
  line-height: 1.5em
  font-weight: 600
  border-bottom: none
  max-height: 24px

.revlist::-webkit-scrollbar
  display: none
  -ms-overflow-style: none
  scrollbar-width: none
</style>
