<template>
  <q-card class="row no-wrap fullSize">
    <div class="PDFContainer">
      <q-card class="col">
        <pdfembed
          v-if="pdfviewer.showContent"
          disableAnnotationLayer
          :source="pdfviewer.pdfSource"
        ></pdfembed>
        <div v-else class="row justify-center q-mt-lg q-mb-lg">
          <q-spinner color="primary" size="3em" :thickness="10" />
        </div>
      </q-card>
      <q-card v-show="pdfviewer.compareWith != null" class="col">
        <pdfembed
          v-if="pdfviewer.showCompareContent"
          disableAnnotationLayer
          :source="pdfviewer.compareSource"
        ></pdfembed>
        <div v-else class="row justify-center q-mt-lg q-mb-lg">
          <q-spinner color="primary" size="3em" :thickness="10" />
        </div>
      </q-card>
    </div>
  </q-card>
</template>

<script lang="coffee">
import { defineComponent, ref, onMounted, reactive, onUnmounted,watch } from 'vue'
import pdfembed from 'vue-pdf-embed'
import { Pdfviewer  } from "./pdfviewerCls.coffee"
export default defineComponent({
  components: {pdfembed}
  name: 'pdfviewer',
  props:
    instancename:
      type: String
      default: 'pdfviewer'
    resource:
      type: Object
      default: null
    compareWith:
      type: Object
      default: null
  setup: (props) ->
    pdfviewer = reactive(new Pdfviewer({props:props}))
    pdfviewer.vueBinder()
    onMounted(() => pdfviewer.mounted())
    onUnmounted(() => pdfviewer.unmounted())

    watch(() =>
      props.resource
    ,(current,old) =>
      pdfviewer.changeRevision(current)
    ,{immediate:true}
    )

    watch(() =>
      props.compareWith
    ,(current,old) =>
      pdfviewer.changeCompare(current)
    ,{immediate:true}
    )
    return {pdfviewer}

})
</script>
<style lang="sass">
.PDFContainer
    display: flex
    width: 100%
    height: 100%
    flex-direction: column
    overflow: scroll
.fullSize

    width: 100%
    height: 100%
</style>
